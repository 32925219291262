












































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import {
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiNumeric4Circle,
    mdiNumeric7Circle,
    mdiNumeric5Circle,
    mdiNumeric6Circle,
} from "@mdi/js";
@Component({
    components: { BackButton },
})
export default class FaqHowDoILoginToMyDStvAccount extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    twoIcon: string = mdiNumeric2Circle;
    threeIcon: string = mdiNumeric3Circle;
    fourIcon: string = mdiNumeric4Circle;
    fiveIcon: string = mdiNumeric5Circle;
    sixIcon: string = mdiNumeric6Circle;
    sevenIcon: string = mdiNumeric7Circle;
    panel: Array<number> = [];

    mounted() {
        const method = this.$route.query["method"];
        if (method === "online") {
            this.panel = [0, 0];
        }

        if (method === "app") {
            this.panel = [1, 1];
        }

        if (method === "whatsapp") {
            this.panel = [2, 2];
        }
    }
}
